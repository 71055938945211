import React from "react";

export const SearchIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.485 20.154L13.223 13.892C12.723 14.318 12.148 14.6477 11.498 14.881C10.848 15.1143 10.1947 15.231 9.53798 15.231C7.93665 15.231 6.58132 14.6767 5.47198 13.568C4.36265 12.4593 3.80798 11.1043 3.80798 9.50302C3.80798 7.90168 4.36198 6.54601 5.46998 5.43601C6.57798 4.32601 7.93265 3.77035 9.53398 3.76901C11.1353 3.76768 12.4913 4.32235 13.602 5.43301C14.7127 6.54368 15.268 7.89935 15.268 9.50001C15.268 10.1947 15.145 10.867 14.899 11.517C14.653 12.167 14.3296 12.723 13.929 13.185L20.191 19.446L19.485 20.154ZM9.53898 14.23C10.8656 14.23 11.9857 13.7733 12.899 12.86C13.8123 11.9467 14.269 10.8263 14.269 9.49901C14.269 8.17168 13.8123 7.05168 12.899 6.13901C11.9857 5.22635 10.8656 4.76968 9.53898 4.76901C8.21232 4.76835 7.09198 5.22501 6.17798 6.13901C5.26398 7.05301 4.80732 8.17301 4.80798 9.49901C4.80865 10.825 5.26532 11.945 6.17798 12.859C7.09065 13.773 8.21065 14.2297 9.53798 14.229"
      fill="black"
    />
  </svg>
);
