import React, { useEffect, useState } from "react";

import "./styles.scss";
import { Link } from "react-router-dom";
import { Working } from "../working";
import { Countries } from "../countries";

export const Footer = ({ setPopup, popup }) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 15) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <footer className="footer">
      <Countries setPopup={setPopup} popup={popup} />
      <Working setPopup={setPopup} popup={popup} />
      <section className="footer-bottom">
        <div className="footer-bottom__container _container">
          <Link to="/" className="footer-bottom__logo">
            <img src="/assets/images/logo/logo.png" alt="Digital Agency" />
          </Link>
          {/* <div className="footer-bottom__menu">
            <Link to="/" className="footer-bottom__menu-link">
              Home
            </Link>
            <Link to="/cooperation" className="footer-bottom__menu-link">
              Cooperation
            </Link>
            <Link to="/contact" className="footer-bottom__menu-link">
              Connect with us
            </Link>
          </div> */}
        </div>
      </section>
    </footer>
  );
};
