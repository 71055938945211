import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Large selection of products',
        description: 'We work with many products, ach of which goes through a thorough selection and tests'
    },
    {
        icon: '02',
        title: 'Unique geos',
        description: 'Our company works in unique geographies and is constantly expanding them, attracting people to our projects'
    },
    {
        icon: '03',
        title: 'We are top partners',
        description: 'We focus on quality products and we are open to mutually beneficial cooperation.'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row" style={{alignItems: 'baseline'}}>
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;