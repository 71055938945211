import React, { useEffect, useState } from "react";

import "./styles.scss";
import "./mixins.scss";
import {
  Advantages,
  Footer,
  Intro,
  Popup,
  BurgerMenu,
  Header
} from "../component/partners";
import { ToTop } from "../component/toTop";
import { Helmet } from "react-helmet";
import { SignUpPopupEN } from "../component/signUpPopup";

export const Partners = () => {
  const [sidebar, setSidebar] = useState(false);
  const [popup, setPopup] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 15) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleAnchor = (url) => {
    document.getElementById(url).scrollIntoView({
      behavior: "smooth",
      block: "center",
    });

    setSidebar(false);
  };

  return (
    <>
      <Helmet>
        <title>Partners page</title>
        <meta name="description" content="Offer" />
      </Helmet>
      <BurgerMenu
        handleAnchor={handleAnchor}
        setSidebar={setSidebar}
        sidebar={sidebar}
      />
      <SignUpPopupEN setPopup={setPopup} popup={popup} />
      <ToTop scrolled={scrolled} />
      <div className="wrapper-partner">
        <Header
          handleAnchor={handleAnchor}
          setSidebar={setSidebar}
          sidebar={sidebar}
          setPopup={setPopup}
          popup={popup}
        />
        <Intro setPopup={setPopup} popup={popup} />
        <Advantages setPopup={setPopup} popup={popup} />
        <Footer setPopup={setPopup} popup={popup} />
      </div>
    </>
  );
};
