import React from "react";

export const ArrowIcon = () => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.07103 7.70699L1.41403 13.364L3.05176e-05 11.95L4.95003 6.99999L3.05176e-05 2.04999L1.41403 0.635986L7.07103 6.29299C7.2585 6.48051 7.36382 6.73482 7.36382 6.99999C7.36382 7.26515 7.2585 7.51946 7.07103 7.70699Z"
      fill="#1F1F25"
    />
  </svg>
);
