import React from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

export const BurgerMenu = ({ setSidebar, sidebar }) => {
  return (
    <div className={`burger-menu ${sidebar ? "active" : ""}`}>
      <div className="burger-menu__btn" onClick={() => setSidebar(!sidebar)}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L15 15M1.00003 15L8.00003 8L15 1"
            stroke="#255742"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      </div>

      <Link to="/" className="burger-menu__link">
        Home
      </Link>
      <Link to="/partners" className="burger-menu__link">
        Cooperation
      </Link>
      <Link to="/partners" className="burger-menu__link">
        Connect with us
      </Link>
      <Link to="/catalog" className="burger-menu__link">
        Catalog
      </Link>
    </div>
  );
};
