import React, { Component } from "react";
// import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import { IoShareSocialOutline } from "react-icons/io5";

class ContactFour extends Component {
    render() {
        return (
            <div className="contact-form--1 pt-5">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-12">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Connect with us.</h2>
                                {/*<p className="description">Для связи с нами можно использовать любой возможный способ. По <b>указанным телефонам</b> Вы можете найти нас во всех соц сетях и <b>месенджерах</b>. Вы так же всегда можете написать нам на <b>email</b>.</p>*/}
                            </div>
                        </div>

                        {/* Start Single Address  */}
                        {/* <div className="col-12">
                            <div className="rn-address">
                                <div className="icon">
                                    <IoShareSocialOutline />
                                </div>
                                <div className="inner">
                                    <h4 className="title">Telegram</h4>
                                    <p><a href="https://t.me/inbguru" target="_blank" rel="noopener noreferrer">t.me/inbguru</a> - Rozhden</p>
                                </div>
                            </div>
                        </div> */}

                        <div className="col-12 mt-5">
                            <div className="rn-address">
                                <div className="icon">
                                    <IoShareSocialOutline />
                                </div>
                                <div className="inner">
                                <h4 className="title">Email</h4>
                                    <p><a href="mailto:hello@inb.bio" rel="noopener noreferrer">hello@inb.bio</a></p>
                                </div>
                            </div>
                        </div>
                        {/* End Single Address  */}

                        {/* Start Single Address  */}
                        {/*<div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">*/}
                        {/*    <div className="rn-address">*/}
                        {/*        <div className="icon">*/}
                        {/*            <FiMail />*/}
                        {/*        </div>*/}
                        {/*        <div className="inner">*/}
                        {/*            <h4 className="title">Email</h4>*/}
                        {/*            <p><a href="mailto:roz@inb-team.com">roz@inb-team.com</a></p>*/}
                        {/*            <p><a href="mailto:mark@inb-team.com">mark@inb-team.com</a></p>*/}
                        {/*            <p><a href="mailto:ruslan@inb-team.com">ruslan@inb-team.com</a></p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/* End Single Address  */}

                        {/* Start Single Address  */}
                        {/*<div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">*/}
                        {/*    <div className="rn-address">*/}
                        {/*        <div className="icon">*/}
                        {/*            <FiMapPin />*/}
                        {/*        </div>*/}
                        {/*        <div className="inner">*/}
                        {/*            <h4 className="title">Адрес</h4>*/}
                        {/*            <p>61100, Украина, Харьков <br /> ул. Сумская, 10</p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/* End Single Address  */}
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactFour;