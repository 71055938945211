import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { SearchIcon } from "../../../assets";
import { useClickOutside, useDebounce } from "../../../utils";
import { baseApi } from "../../../api/BaseApi";
import { Link, useParams } from "react-router-dom";

export const Searcher = () => {
  const { slug } = useParams();
  const [query, setQuery] = useState("");
  const [offers, setOffers] = useState([]);

  const debouncedQuery = useDebounce(query, 500);

  const selectedValueFieldRef = useRef(null);
  const optionsListRef = useRef(null);

  useEffect(() => {
    if (debouncedQuery.length) {
      baseApi.getOffers(1, 10, debouncedQuery, "", "").then((res) => {
        if (res.statusCode === 200) {
          setOffers(res.data.data);
        }
      });
    }
  }, [debouncedQuery]);

  useEffect(() => {
    setOffers([]);
    setQuery("");
  }, [slug]);

  useClickOutside(
    optionsListRef,
    () => {
      setOffers([]);
      setQuery("");
    },
    selectedValueFieldRef.current
  );

  return (
    <div className="search">
      <div className="search__wrapper">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search"
        />
        <SearchIcon />
        {!!offers.length && (
          <div className="search__options" ref={optionsListRef}>
            {offers.map((el) => (
              <Link
                key={el.id}
                to={`/catalog/${el.id}`}
                className="search__option"
              >
                {el.name}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
