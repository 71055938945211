export default class BaseApi {
  baseUrl = process.env.REACT_APP_API_HOST;

  async fetchData(path, requestOptions) {
    try {
      const response = await fetch(`${this.baseUrl}${path}`, {
        ...requestOptions,
      });
      const statusCode = response.status;
      const data = await response.json();
      return {
        data,
        statusCode,
      };
    } catch (e) {
      throw new Error(`API Fetch error: ${e}`);
    }
  }

  async getData(path, customHeaders) {
    let myHeaders = {};

    myHeaders = { ...customHeaders };

    myHeaders["Content-Type"] = "application/json";

    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };
    return this.fetchData(path, requestOptions);
  }

  async postData(path, data) {
    const myHeaders = {};
    myHeaders["Content-Type"] = "application/json";

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      referrerPolicy: "no-referrer",
    };

    return this.fetchData(path, requestOptions);
  }

  async getCategories() {
    return this.getData("/public/categories");
  }

  async getCountries() {
    return this.getData("/public/countries");
  }

  async getOffers(page, per_page, name, country_id, category_id) {
    const params = new URLSearchParams({
      page: page.toString(),
      per_page: per_page.toString(),
      sort: "site_rank",
      order: "asc",
    });

    if (name) {
      params.append("search[name]", name);
    }

    if (country_id) {
      params.append("filter[country_id]", country_id.toString());
    }

    if (category_id) {
      params.append("filter[category_id]", category_id.toString());
    }

    return this.getData(`/public/offers?${params.toString()}`);
  }

  async getOffer(id) {
    return this.getData(`/public/offers/${id}`);
  }
}

export const baseApi = new BaseApi();
