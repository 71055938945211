import React ,{ Component }from "react";
import { FiUsers , FiMonitor } from "react-icons/fi";
import { GiCrescentBlade, GiSeaCreature, GiTakeMyMoney } from "react-icons/gi";
import { MdMoneyOff } from "react-icons/md";

const AdvantageList = [
    {
        icon: <GiCrescentBlade />,
        title: 'Products',
        description: 'We offer many trending solutions, ensuring not just survival, but constant development of your business.'
    },
    {
        icon: <MdMoneyOff />,
        title: 'Tests',
        description: 'Our team regularly conducts tests to ensure the reliability and effectiveness of our products and services.'
    },
    {
        icon: <FiUsers />,
        title: 'Limited circle of partners',
        description: 'We select a limited number of partners, striving for in-depth cooperation and mutual support, ensuring high quality services and effective partnerships.'
    },
    { 
        icon: <FiMonitor />,
        title: 'Software',
        description: 'The department specializes in creating new and supporting existing software. We quickly solve any technological problems.'
    },
    {
        icon: <GiSeaCreature />,
        title: 'Your own creative department',
        description: 'Our creative department is a team of creative specialists ready to realize any ideas to life.'
    },
    { 
        icon: <GiTakeMyMoney />,
        title: 'Transparent payments',
        description: 'We guarantee transparent and timely payments. We have no delays - every payment is important, and we ensure that obligations are fulfilled on time and honestly.'
    }
]


class AdvantageThree extends Component{
    render(){
        const {column } = this.props;
        const AdvantageContent = AdvantageList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {AdvantageContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <div className="service service__style--2">
                                <div className="icon">
                                    {val.icon}
                                </div>
                                <div className="content">
                                    <h3 className="title">{val.title}</h3>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default AdvantageThree;
