import React from "react";

export const BackIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 10L3.293 10.707L2.586 10L3.293 9.293L4 10ZM21 18C21 18.2652 20.8946 18.5196 20.7071 18.7071C20.5196 18.8946 20.2652 19 20 19C19.7348 19 19.4804 18.8946 19.2929 18.7071C19.1054 18.5196 19 18.2652 19 18H21ZM8.293 15.707L3.293 10.707L4.707 9.293L9.707 14.293L8.293 15.707ZM3.293 9.293L8.293 4.293L9.707 5.707L4.707 10.707L3.293 9.293ZM4 9H14V11H4V9ZM21 16V18H19V16H21ZM14 9C15.8565 9 17.637 9.7375 18.9497 11.0503C20.2625 12.363 21 14.1435 21 16H19C19 14.6739 18.4732 13.4021 17.5355 12.4645C16.5978 11.5268 15.3261 11 14 11V9Z"
      fill="#255742"
    />
  </svg>
);
