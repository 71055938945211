import React from "react";
import "./styles.scss";

export const ToTop = ({ scrolled }) => {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div onClick={toTop} className={`to-top__btn ${scrolled ? "active" : ""}`}>
      <img
        src="/assets/images/partners/circle.png"
        className="to-top__btn-circle"
        alt="Circle"
      />
      <img
        src="/assets/images/partners/arrow.png"
        className="to-top__btn-arrow"
        alt="Circle"
      />
    </div>
  );
};
