import React, { useEffect, useState } from "react";

import "./styles.scss";
import "./mixins.scss";
import {
  Advantages,
  Footer,
  Header,
  Intro,
  Popup,
  Sidebar,
} from "../component/partners";
import { Helmet } from "react-helmet";

export const Partners = () => {
  const [sidebar, setSidebar] = useState(false);
  const [popup, setPopup] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 15) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleAnchor = (url) => {
    document.getElementById(url).scrollIntoView({
      behavior: "smooth",
      block: "center",
    });

    setSidebar(false);
  };

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Helmet pageTitle="Partners page" />
      <Sidebar
        handleAnchor={handleAnchor}
        setSidebar={setSidebar}
        sidebar={sidebar}
      />
      <Popup setPopup={setPopup} popup={popup} />
      <div
        onClick={toTop}
        className={`to-top__btn ${scrolled ? "active" : ""}`}
      >
        <img
          src="/assets/images/partners/circle.png"
          className="to-top__btn-circle"
          alt="Circle"
        />
        <img
          src="/assets/images/partners/arrow.png"
          className="to-top__btn-arrow"
          alt="Circle"
        />
      </div>
      <div className="wrapper-partner">
        <Header
          handleAnchor={handleAnchor}
          setSidebar={setSidebar}
          sidebar={sidebar}
          setPopup={setPopup}
          popup={popup}
        />
        <Intro setPopup={setPopup} popup={popup} />
        <Advantages setPopup={setPopup} popup={popup} />
        <Footer setPopup={setPopup} popup={popup} />
      </div>
    </>
  );
};
