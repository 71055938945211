import React, { useEffect, useState } from "react";
import "./styles.scss";
import "./mixins.scss";
import { BurgerMenu, Header, OffersTable, Sidebar } from "../component/offers";
import { Helmet } from "react-helmet";
import { useParams, useHistory } from "react-router-dom";
import { baseApi } from "../api/BaseApi";

export const Offer = () => {
  const { slug } = useParams();
  const history = useHistory();
  const [sidebar, setSidebar] = useState(false);
  const [offers, setOffers] = useState([]);
  const [offer, setOffer] = useState(null);
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    baseApi.getOffer(slug).then((res) => {
      if (res.statusCode === 200) {
        setOffer(res.data);
      } else {
        history.push("/catalog");
      }
    });
  }, [slug]);

  useEffect(() => {
    if (offer) {
      baseApi
        .getOffers(1, 5, "", offer.country_id, offer.category_id)
        .then((res) => {
          if (res.statusCode === 200) {
            setOffers(res.data.data);
          }
        });
    }
  }, [offer]);

  return (
    <>
      <Helmet>
        <title>{`${offer ? `${offer.name}` : "Offer page"} `}</title>
        <meta name="description" content={offer?.name || "Offer"} />
      </Helmet>
      <BurgerMenu setSidebar={setSidebar} sidebar={sidebar} />
      <div className="wrapper-offer">
        <Header
          setSidebar={setSidebar}
          sidebar={sidebar}
          bg="#255742"
          setPopup={setPopup}
          popup={popup}
        />
        <div className="offer">
          <div className="offer__container _container">
            <div className="offer__sidebar">
              <Sidebar />
            </div>
            <div className="offer__content">
              {offer && (
                <div className="offer__wrapper">
                  <div className="offer__global">
                    <div className="offer__global-logo">
                      <img src={offer.image_url} alt={offer.name} />
                    </div>
                    <div className="offer__global-info">
                      <div className="offer__global-header">
                        <div className="offer__global-id">
                          {`ID ${offer.id.split("-")[0]}`}
                        </div>
                        <div className="offer__global-active">Active</div>
                      </div>
                      <h4>{offer.name}</h4>
                      <p>{offer.category_name}</p>
                      <div
                        className="offer__global-btn"
                        onClick={() => setPopup(true)}
                      >
                        <span>Launch the offer</span>
                      </div>
                    </div>
                  </div>
                  <div className="offer__counts">
                    <div className="offer__counts-item">
                      <p>Payout</p>
                      <h6>{offer.payout}</h6>
                    </div>
                    <div className="offer__counts-item">
                      <p>Price</p>
                      <h6>{offer.price}</h6>
                    </div>
                    <div className="offer__counts-item">
                      <p>Model</p>
                      <h6>{offer.model}</h6>
                    </div>
                    <div className="offer__counts-item">
                      <p>Approve</p>
                      <h6>{offer.approve}</h6>
                    </div>
                    <div className="offer__counts-item">
                      <p>Geo</p>
                      <h6>{offer.country_code}</h6>
                    </div>
                  </div>
                  <div className="offer__description">
                    <h5>Rules</h5>
                    <div
                      className="offer__description-wrapper"
                      dangerouslySetInnerHTML={{ __html: offer.description }}
                    />
                  </div>
                </div>
              )}
              {!!offers.length && <h4>Top 5 offers</h4>}
              <OffersTable offers={offers} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
