import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

export const Header = ({
  setSidebar,
  sidebar,
  setPopup,
  popup,
  handleAnchor,
}) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 15) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`partner-header ${scrolled ? "scrolled" : ""}`}>
      <div className="partner-header__container _container">
        <Link to="/" className="partner-header__logo">
          <img
            src={
              scrolled
                ? "/assets/images/logo/logo-green.png"
                : "/assets/images/logo/logo.png"
            }
            alt="Digital Agency"
          />
        </Link>
        <div className="partner-header__menu">
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              handleAnchor("adwantages");
            }}
            className={`partner-header__menu-link ${
              scrolled ? "partner-header__menu-scrolled" : ""
            }`}
          >
            Advantages
          </a>
          <a
            href="countries"
            onClick={(e) => {
              e.preventDefault();
              handleAnchor("countries");
            }}
            className={`partner-header__menu-link ${
              scrolled ? "partner-header__menu-scrolled" : ""
            }`}
          >
            Countries
          </a>
          <Link
            to="/partners"
            className={`partner-header__menu-link ${
              scrolled ? "partner-header__menu-scrolled" : ""
            }`}
          >
            Cooperation
          </Link>
          <Link
            to="/catalog"
            className={`partner-header__menu-link ${
              scrolled ? "partner-header__menu-scrolled" : ""
            }`}
          >
            Catalog
          </Link>
        </div>
        <div
          className={`partner-header__btns ${
            scrolled ? "partner-header__btns-scrolled" : ""
          }`}
        >
          <div
            className={`partner-header__btn ${
              scrolled ? "partner-header__btn-scrolled" : ""
            }`}
            onClick={() => setPopup(true)}
          >
            <span>Sign Up</span>
          </div>
          <a
            className={`partner-header__btn ${
              scrolled ? "partner-header__btn-scrolled" : ""
            }`}
            target="_blank"
            rel="noopener noreferrer"
            href="https://cabinet.inb.bio/"
          >
            <span>Sign In</span>
          </a>
        </div>
        <div
          className={`partner-header__burger ${
            scrolled ? "partner-header__burger-scrolled" : ""
          }`}
          onClick={() => setSidebar(!sidebar)}
        >
          <svg
            width="40"
            height="33"
            viewBox="0 0 40 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="2.51662" width="27" height="4" fill="white" />
            <circle cx="36" cy="4.51662" r="4" fill="white" />
            <rect y="15.5166" width="40" height="4" fill="white" />
            <rect y="28.5166" width="40" height="4" fill="white" />
          </svg>
        </div>
      </div>
    </header>
  );
};
