import React, { useCallback, useEffect, useMemo, useState } from "react";

import "./styles.scss";
import "./mixins.scss";
import { Header, OffersTable, Sidebar } from "../component/offers";
import { Helmet } from "react-helmet";
import { BurgerMenu } from "../component/offers";
import { baseApi } from "../api/BaseApi";
import { useDebounce } from "../utils";
import { ArrowIcon } from "../assets";

export const Catalog = () => {
  const [sidebar, setSidebar] = useState(false);
  const [query, setQuery] = useState("");
  const [category, setCategory] = useState(null);
  const [country, setCountry] = useState(null);
  const [offers, setOffers] = useState([]);
  const [page, setPage] = useState(1);
  const [pageMeta, setPageMeta] = useState(null);
  const [popup, setPopup] = useState(false);

  const debouncedQuery = useDebounce(query, 500);

  const nextPage = () => {
    setPage((prev) => (prev < pageMeta.last_page ? prev + 1 : prev));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const prevPage = () => {
    setPage((prev) => (prev >= 2 ? prev - 1 : prev));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const generatePagination = useMemo(() => {
    const pages = [];

    if (!pageMeta) {
      return pages;
    }

    if (pageMeta.last_page <= 5) {
      for (let i = 1; i <= pageMeta.last_page; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);

      if (page > 4) {
        pages.push("...");
      }

      const startPage = Math.max(2, page - 2);
      const endPage = Math.min(pageMeta.last_page - 1, page + 2);
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (page < pageMeta.last_page - 3) {
        pages.push("...");
      }

      pages.push(pageMeta.last_page);
    }

    return pages;
  }, [pageMeta, page]);

  useEffect(() => {
    baseApi
      .getOffers(
        page,
        10,
        "",
        country ? country.id : "",
        category ? category.id : ""
      )
      .then((res) => {
        if (res.statusCode === 200) {
          setOffers(res.data.data);
          setPageMeta(res.data.meta);
        }
      });
  }, [category, country, page]);

  return (
    <>
      <Helmet>
        <title>Catalog</title>
        <meta name="description" content="Catalog" />
      </Helmet>
      <BurgerMenu setSidebar={setSidebar} sidebar={sidebar} />
      <div className="wrapper-catalog">
        <Header
          setSidebar={setSidebar}
          sidebar={sidebar}
          bg="#255742"
          setPopup={setPopup}
          popup={popup}
        />
        <div className="catalog">
          <div className="catalog__container _container">
            <div className="catalog__sidebar">
              <Sidebar
                withFilters
                query={query}
                setQuery={setQuery}
                setCategory={setCategory}
                category={category}
                country={country}
                setCountry={setCountry}
                setPage={setPage}
                setPopup={setPopup}
                popup={popup}
              />
            </div>
            <div className="catalog__content">
              <OffersTable offers={offers} />
              {!!pageMeta && !!offers.length && (
                <div className="catalog__pagination">
                  <div className="catalog__pagination-prev" onClick={prevPage}>
                    <ArrowIcon />
                  </div>
                  {generatePagination.map((generatedPage, index) =>
                    generatedPage === "..." ? (
                      <span key={index} className="dots">
                        ...
                      </span>
                    ) : (
                      <div
                        key={index}
                        className={`catalog__pagination-number ${
                          page === generatedPage ? "active" : ""
                        }`}
                        onClick={() => {
                          setPage(generatedPage);
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <span>{generatedPage}</span>
                      </div>
                    )
                  )}
                  <div className="catalog__pagination-next" onClick={nextPage}>
                    <ArrowIcon />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
