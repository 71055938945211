import React, { useEffect, useState } from "react";
import {
  CatalogIcon,
  HomeIcon,
  BackIcon,
  FacebookIcon,
  LinkedinIcon,
  InstagramIcon,
  TelegramIcon,
  SearchIcon,
} from "../../../assets";
import { Link } from "react-router-dom";
import "./styles.scss";
import { baseApi } from "../../../api/BaseApi";
import { CustomSelect } from "../custom-select";
import { Searcher } from "../searcher";

export const Sidebar = ({
  category,
  country,
  setCategory,
  setCountry,
  query,
  setQuery,
  withFilters,
  setPage,
  setPopup,
  popup,
}) => {
  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [backLink, setBackLink] = useState("");

  const loadInitialData = async () => {
    setLoading(true);

    const [categoriesApi, countriesApi] = await Promise.all([
      baseApi.getCategories(),
      baseApi.getCountries(),
    ]);

    if (categoriesApi.statusCode === 200) {
      setCategories(categoriesApi.data.categories);
    }

    if (countriesApi.statusCode === 200) {
      setCountries(countriesApi.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (withFilters) {
      loadInitialData();
    }

    const pageHistory = JSON.parse(localStorage.getItem("pageHistory")) || {
      current: "/",
      previous: "/",
    };

    setBackLink(pageHistory.previous);
  }, []);

  return (
    <div className={`sidebar ${withFilters ? "withFilters" : ""}`}>
      <div className="sidebar__main">
        <div className="sidebar__menu">
          <Link to="/" className="sidebar__menu-link">
            <HomeIcon />
            <span>Home</span>
          </Link>
          <Link to="/catalog" className="sidebar__menu-link">
            <CatalogIcon />
            <span>Catalog</span>
          </Link>
          <Link to={backLink} className="sidebar__menu-link">
            <BackIcon />
            <span>Back</span>
          </Link>
        </div>
        <div className="sidebar__social">
          <span>Social links:</span>
          <div className="sidebar__social-wrapper">
            <a href="" className="sidebar__social-link">
              <TelegramIcon />
            </a>
            <a href="" className="sidebar__social-link">
              <FacebookIcon />
            </a>
            <a href="" className="sidebar__social-link">
              <LinkedinIcon />
            </a>
            <a href="" className="sidebar__social-link">
              <InstagramIcon />
            </a>
          </div>
        </div>
      </div>

      <div className="sidebar__filters">
        <Searcher />
        {withFilters && (
          <>
            <CustomSelect
              label="Categories"
              onChange={setCategory}
              value={category}
              options={categories}
              setPage={setPage}
            />
            <CustomSelect
              label="Countries"
              onChange={setCountry}
              value={country}
              options={countries}
              setPage={setPage}
            />
            <div
              className="sidebar__btn"
              onClick={() => setPopup(true)}
            >
              <span>Launch the offer</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
