import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import { IoShareSocialOutline } from "react-icons/io5";
// import GoogleMapReact from 'google-map-react';
// import ContactTwo from "../elements/contact/ContactTwo";
// import BrandTwo from "../elements/BrandTwo";
import CounterOne from "../elements/counters/CounterOne";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Contact' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17" data-dark-overlay="4" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Contact us</h2>
                                    <p>We are open to any suggestions, write to any convenient communication channel.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">

                        <div className="row">
                            {/* Start Single Address  */}
                            {/* <div className="col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <IoShareSocialOutline />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Telegram</h4>
                                        <p><a href="https://t.me/inbguru" target="_blank" rel="noopener noreferrer">t.me/inbguru</a> - Rozhden</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-12 mt-5">
                                <div className="rn-address">
                                    <div className="icon">
                                        <IoShareSocialOutline />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email</h4>
                                        <p><a href="mailto:hello@inb.bio" rel="noopener noreferrer">hello@inb.bio</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            {/*<div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">*/}
                            {/*    <div className="rn-address">*/}
                            {/*        <div className="icon">*/}
                            {/*            <FiMail />*/}
                            {/*        </div>*/}
                            {/*        <div className="inner">*/}
                            {/*            <h4 className="title">Email</h4>*/}
                            {/*            <p><a href="mailto:roz@inb-team.com">roz@inb-team.com</a></p>*/}
                            {/*            <p><a href="mailto:mark@inb-team.com">mark@inb-team.com</a></p>*/}
                            {/*            <p><a href="mailto:ruslan@inb-team.com">ruslan@inb-team.com</a></p>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            {/*<div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">*/}
                            {/*    <div className="rn-address">*/}
                            {/*        <div className="icon">*/}
                            {/*            <FiMapPin />*/}
                            {/*        </div>*/}
                            {/*        <div className="inner">*/}
                            {/*            <h4 className="title">Адрес</h4>*/}
                            {/*            <p>61100, Украина, Харьков <br /> ул. Сумская, 10</p>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pt--110 pb--110 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">In numbers</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Contact