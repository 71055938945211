import React, { useRef, useState } from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

import "./styles.scss";

SwiperCore.use([Navigation]);

const NextArrow = ({ className }) => {
  return (
    <div className={className}>
      <img src="/assets/images/partners/row-arrow.png" alt="" />
    </div>
  );
};

const PrevArrow = ({ className }) => {
  return (
    <div className={className}>
      <img src="/assets/images/partners/row-arrow.png" alt="" />
    </div>
  );
};

const initialCountries = [
  {
    id: 1,
    name: "Guinea",
    population: "13.86 million",
    salary: 280,
    flag: "/assets/images/partners/guinea.png",
    img: "/assets/images/partners/guinea-map.png",
  },
  {
    id: 2,
    name: "Tanzania",
    population: "65,5 million",
    salary: 215,
    flag: "/assets/images/partners/tanzania.png",
    img: "/assets/images/partners/tanzania-map.png",
  },
  {
    id: 3,
    name: "Zambia",
    population: "20,02 million",
    salary: 223,
    flag: "/assets/images/partners/zambia.png",
    img: "/assets/images/partners/zambia-map.png",
  },
  {
    id: 4,
    name: "Bangladesh",
    population: "171,2 million",
    salary: 245,
    flag: "/assets/images/partners/bangladesh.png",
    img: "/assets/images/partners/bangladesh-map.png",
  },
  {
    id: 5,
    name: "Pakistan",
    population: "235,8 million",
    salary: 293,
    flag: "/assets/images/partners/pakistan.png",
    img: "/assets/images/partners/pakistan-map.png",
  },
  {
    id: 6,
    name: "Senegal",
    population: "17,32 million",
    salary: 181,
    flag: "/assets/images/partners/senegal.png",
    img: "/assets/images/partners/senegal-map.png",
  },
  {
    id: 7,
    name: "Benin",
    population: "13,35 millionов",
    salary: 160,
    flag: "/assets/images/partners/benin.png",
    img: "/assets/images/partners/benin-map.png",
  },
  {
    id: 8,
    name: "Venezuela",
    population: "28,3 millionа",
    salary: 250,
    flag: "/assets/images/partners/venezuela.png",
    img: "/assets/images/partners/venezuela-map.png",
  },
];

export const Countries = ({ setPopup, popup }) => {
  const [countries, setCountries] = useState(initialCountries);
  const swiperRef = useRef(null);

  return (
    <section id="countries" className="countries">
      <div className="countries__container _container">
        <div className="countries__text">
        <h1 className="countries__title">Our new countries.</h1>
          <p className="countries__description">
            <b>We work in unique geos,</b> which will ensure high sales and{" "}
            <b>increased profits</b>
          </p>
        </div>

        <div className="countries__slider">
          <div className="countries__navigation">
            <PrevArrow className="swiper-button-prev" />
            <NextArrow className="swiper-button-next" />
          </div>
          <Swiper
            ref={swiperRef}
            spaceBetween={40}
            centeredSlides={true}
            slidesPerView={1}
            loop={true}
            speed={500}
            modules={[Navigation]}
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
          >
            {countries.map((value, index) => (
              <SwiperSlide key={value.id}>
                <div className="countries__slide" key={index}>
                  <div className="countries__slide-map">
                    <img src={value.img} alt="map" />
                  </div>
                  <div className="countries__card">
                    <div className="countries__card-content">
                      <img
                        src={value.flag}
                        alt="flag"
                        className="countries__card-logo"
                      />
                      <h2>{value.name}</h2>
                      <div className="countries__card-counts">
                        <div className="countries__card-count">
                          <span>Population:</span>
                          <p>
                            <b>{value.population}</b>
                          </p>
                        </div>
                        <div className="countries__card-count">
                          <span>Average salary:</span>
                          <p>
                            <b>{value.salary}$</b>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      className="countries__card-link"
                      onClick={() => setPopup(true)}
                    >
                      <span>All countries</span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};
