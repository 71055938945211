import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { useClickOutside } from "../../../utils";
import { ArrowIcon } from "../../../assets";

export const CustomSelect = ({ label, options, onChange, value, setPage }) => {
  const [open, setOpen] = useState(false);

  const selectedValueFieldRef = useRef(null);
  const optionsListRef = useRef(null);

  useClickOutside(
    optionsListRef,
    () => {
      setOpen(false);
    },
    selectedValueFieldRef.current
  );

  // useEffect(() => {
  //   const optionsList = optionsListRef.current;

  //   console.log(optionsList)

  //   const handleWheel = (event) => {
  //     if (!optionsList) return;

  //     const deltaY = event.deltaY;
  //     const atTop = optionsList.scrollTop === 0;
  //     const atBottom =
  //       optionsList.scrollTop + optionsList.clientHeight >= optionsList.scrollHeight;

  //     if ((atTop && deltaY < 0) || (atBottom && deltaY > 0)) {
  //       event.preventDefault();
  //     }
  //   };

  //   if (optionsList) {
  //     optionsList.addEventListener("wheel", handleWheel);
  //   }

  //   return () => {
  //     if (optionsList) {
  //       optionsList.removeEventListener("wheel", handleWheel);
  //     }
  //   };
  // }, [open]);

  return (
    <div className="select">
      <label>{label}</label>
      <div className="select__wrapper">
        <div
          className="select__value"
          ref={selectedValueFieldRef}
          onClick={() => setOpen(!open)}
        >
          {value ? value.name : "Chose option"}

          <div
            className={
              open ? "select__value-arrow" : "select__value-arrow active"
            }
          >
            <ArrowIcon />
          </div>
        </div>
        {open && (
          <div className="select__options" ref={optionsListRef}>
            <div
              onClick={() => {
                onChange(null);
                setOpen(false);
                setPage(1);
              }}
              className="select__option"
            >
              Clear
            </div>
            {options.map((el) => (
              <div
                key={el.id}
                onClick={() => {
                  onChange(el);
                  setOpen(false);
                  setPage(1);
                }}
                className="select__option"
              >
                {el.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
