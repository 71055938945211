import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import "./styles.scss";
import { SignUpPopupEN } from "../../signUpPopup";

export const Header = ({ setSidebar, sidebar, bg, setPopup, popup }) => {
  useEffect(() => {}, []);

  return (
    <header className="offers-header" style={{ background: bg ? bg : "" }}>
      <div className="offers-header__container _container">
        <Link to="/" className="offers-header__logo">
          <img src={"/assets/images/logo/logo.png"} alt="Digital Agency" />
        </Link>
        <div className="offers-header__menu">
          <Link to="/" className="offers-header__menu-link">
            Home
          </Link>
          <Link to="/partners" className="offers-header__menu-link">
            Cooperation
          </Link>
          <Link to="/partners" className="offers-header__menu-link">
            Connect with us
          </Link>
          <Link to="/catalog" className="offers-header__menu-link">
            Catalog
          </Link>
        </div>
        <div className="offers-header__btns">
          <div className="offers-header__btn" onClick={() => setPopup(true)}>
            <span>Sign Up</span>
          </div>
          <a
            className="offers-header__btn"
            target="_blank"
            rel="noopener noreferrer"
            href="https://cabinet.inb.bio/"
          >
            <span>Sign In</span>
          </a>
        </div>
        <div
          className="offers-header__burger"
          onClick={() => setSidebar(!sidebar)}
        >
          <svg
            width="40"
            height="33"
            viewBox="0 0 40 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="2.51662" width="27" height="4" fill="white" />
            <circle cx="36" cy="4.51662" r="4" fill="white" />
            <rect y="15.5166" width="40" height="4" fill="white" />
            <rect y="28.5166" width="40" height="4" fill="white" />
          </svg>
        </div>
      </div>
      <SignUpPopupEN setPopup={setPopup} popup={popup} />
    </header>
  );
};
