// React Required
import React, { Component, useEffect } from "react";
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

// Common Layout
// import Layout from "./component/common/App";

// Home layout
// import Demo from './page-demo/Demo';
import MainDemo from "./home/MainDemo";
// import Startup from './home/Startup';
// import Paralax from './home/Paralax';
// import HomePortfolio from './home/HomePortfolio';
// import DigitalAgency from './home/DigitalAgency';
// import CreativeAgency from './home/CreativeAgency';
// import PersonalPortfolio from './home/PersonalPortfolio';
// import Business from './home/Business';
// import StudioAgency from './home/StudioAgency';
// import PortfolioLanding from './home/PortfolioLanding';
// import CreativeLanding from './home/CreativeLanding';
// import HomeParticles from './home/HomeParticles';
// import CreativePortfolio from './home/CreativePortfolio';
// import DesignerPortfolio from './home/DesignerPortfolio';
// import InteriorLanding from './home/Interior';
// import CorporateBusiness from './home/CorporateBusiness';

// Dark Home Layout
// import DarkMainDemo from './dark/MainDemo';
// import DarkPortfolioLanding from './dark/PortfolioLanding';

// Element Layout
// import Service from "./elements/Service";
// import ServiceDetails from "./elements/ServiceDetails";
// import About from "./elements/About";
import Contact from "./elements/Contact";
// import PortfolioDetails from "./elements/PortfolioDetails";
// import Blog from "./elements/Blog";
// import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";

// Blocks Layout
// import Team from "./blocks/Team";
// import Counters from "./blocks/Counters";
// import Testimonial from "./blocks/Testimonial";
// import Portfolio from "./blocks/Portfolio";
// import VideoPopup from "./blocks/VideoPopup";
// import Gallery from "./blocks/Gallery";
// import Brand from "./blocks/Brand";
// import ProgressBar from "./blocks/ProgressBar";
// import ContactForm from "./blocks/ContactForm";
// import GoogleMap from "./blocks/GoogleMap";
// import Columns from "./blocks/Columns";
// import PricingTable from "./blocks/PricingTable";

import { BrowserRouter, Switch, Route, withRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import Cooperation from "./home/Сooperation";
import { Partners } from "./partners";
import { Catalog } from "./catalog";
import { Offer } from "./offer";

const RouteWatcher = ({ location }) => {
  useEffect(() => {
    const pageHistory = JSON.parse(localStorage.getItem("pageHistory")) || {
      current: null,
      previous: null,
    };

    if (pageHistory.current === location.pathname) {
        return;
      }

    localStorage.setItem(
      "pageHistory",
      JSON.stringify({
        previous: pageHistory.current,
        current: location.pathname,
      })
    );
  }, [location]);

  return null;
};

const WatchedRouteWatcher = withRouter(RouteWatcher);

const Root = () => {
  return (
    <BrowserRouter basename={"/"}>
      <WatchedRouteWatcher />
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={MainDemo} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/cooperation`}
          component={Cooperation}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/contact`}
          component={Contact}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/partners`}
          component={Partners}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/catalog`}
          component={Catalog}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/catalog/:slug`}
          component={Offer}
        />

        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
        <Route component={error404} />
      </Switch>
    </BrowserRouter>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
