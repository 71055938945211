import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import "./styles.scss";

export const Popup = ({ setPopup, popup, handleAnchor }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const recaptchaRef = useRef(null);

  const getParams = () => {
    const params = new URLSearchParams(window.location.search);
    const paramsObject = {};
    for (const [key, value] of params.entries()) {
      paramsObject[key] = value;
    }
    return paramsObject;
  };

  const sendFormData = async (formData) => {
    return
    try {
      const url =
        process.env.REACT_APP_API_URL || "https://register.inb.bio:5000/api";
      const response = await fetch(`${url}/user`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData?.message || "Network response was not ok");
      }
      console.log("Data sent successfully");
      return true;
    } catch (error) {
      setError(error?.message || "An unknown error occurred.");
      return false;
    }
  };

  const handleButtonClick = async () => {
    if (!phone) {
      setError("Please fill in your phone number.");
      return;
    }
    const captchaToken = await recaptchaRef?.current?.executeAsync();
    if (!captchaToken) {
      setError("Please complete the CAPTCHA.");
      return;
    }

    const success = await sendFormData({
      name,
      phone,
      params: getParams(),
      captchaToken,
    });
    if (!success) return;
    setName("");
    setPhone("");
    setError("");
    setPopup(false);
    recaptchaRef.current.reset();
  };

  return (
    <div className={`popup ${popup ? "active" : ""}`}>
      <div className="popup__content">
        <div className="popup__header">
          <h4>Давай поговорим</h4>
          <div className="popup__close" onClick={() => setPopup(false)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 5L19 19M5.00003 19L12 12L19 5"
                stroke="#8E8E8E"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            name="name"
            placeholder="Имя*"
          />
          <input
            type="text"
            value={phone}
            required
            onChange={(e) => {
              setPhone(e.target.value);
              if (error) setError("");
            }}
            name="phone"
            placeholder="Телефон/Телеграмма*"
          />
          {/* <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LcgGVoqAAAAAFnd_ArBw1HnULB3dqbyr_mwHxKQ'}
            size="invisible"
          /> */}
          <p>Отправляя форму, вы соглашаетесь с политикой конфиденциальности</p>
          {error && <p className="error-message">{error}</p>}
          <button type="button" onClick={handleButtonClick}>
            <span>Отправить</span>
          </button>
        </form>
      </div>
    </div>
  );
};
