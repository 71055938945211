import React from "react";
import "./styles.scss";
import { Link } from "react-router-dom";

export const OffersTable = ({ offers }) => {
  if (!offers.length) {
    return <div></div>;
  }

  return (
    <div className="offers-table">
      <div className="offers-table__header">
        <div className="offers-table__header-item">ID</div>
        <div className="offers-table__header-item"></div>
        <div className="offers-table__header-item">Offer</div>
        <div className="offers-table__header-item">Approve</div>
        <div className="offers-table__header-item">Payout</div>
        <div className="offers-table__header-item">Rank</div>
      </div>
      <div className="offers-table__list">
        {offers.map((offer) => {
          return (
            <Link
              key={offer.id}
              to={`/catalog/${offer.id}`}
              className="offers-table__item"
            >
              <div className="offers-table__item-wrapper">
                <div className="offers-table__item-id">
                  <span>ID</span> {offer.id.split("-")[0]}
                </div>
              </div>
              <div className="offers-table__item-wrapper">
                <div className="offers-table__item-logo">
                  <img src={offer.image_url} alt={offer.name} />
                </div>
              </div>
              <div className="offers-table__item-wrapper">
                <div className="offers-table__item-name">
                  <h5>{offer.name}</h5>
                  <p>{offer.category_name}</p>
                </div>
              </div>
              <div className="offers-table__item-wrapper">
                <div className="offers-table__item-header">Approve</div>
                <div className="offers-table__item-number">{offer.approve}</div>
              </div>
              <div className="offers-table__item-wrapper">
                <div className="offers-table__item-header">Payout</div>
                <div className="offers-table__item-number">{offer.payout}</div>
              </div>
              <div className="offers-table__item-wrapper">
                <div className="offers-table__item-header">Rank</div>
                <div className="offers-table__item-number">
                  {offer.site_rank}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
