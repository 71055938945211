import React, { Component } from "react";
import { FiPhoneCall , FiStar , FiMonitor, FiPlay } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiStar />,
        title: 'Unique products',
        description: "We are not afraid to create new projects, that's why we have unique products in different industries."
    },
    {
        icon: <FiPhoneCall />,
        title: 'Our own call center',
        description: 'We spend a lot of effort on developing scripts and approval levels.'
    },
    {
        icon: <FiPlay />,
        title: 'Automation',
        description: 'We try to automate everything to minimize the human factor and get the best results.'
    },
    {
        icon: <FiMonitor />,
        title: 'Our own software',
        description: 'We have created software that ensures smooth operation of all stages, providing complete privacy and security.'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Partnership',
        description = 'We are ready to consider various options for cooperation in the field of advertising. But we are watching for its quality, which is why we do not open our doors to everyone.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/partners"><span className="text">Become a partner</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="service service__style--2">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h3 className="title">{val.title}</h3>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
