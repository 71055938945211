import React from 'react';
// import ScrollToTop from 'react-scroll-up';
// import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import AdvantageList from "../elements/advantage/AdvantageList";
// import BlogContent from "../elements/blog/BlogContent";
import Helmet from "../component/common/Helmet";
// import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import ContactFour from "../elements/contact/ContactFour";

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'COOPERATION',
        title: 'about the benefits of working with us.',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]

const AbilityListOne = [
    {
        icon: "icon-01.png",
        title: 'Работал(и) на бурже',
        description: 'Был опыт работы с разными странами и разными оферами (не СНГ).'
    },
    {
        icon: "icon-02.png",
        title: 'Работал с серыми оферами',
        description: 'Знаешь что такое клоака, как лить через FB и оставаться долго на плаву.'
    },
    {
        icon: "icon-03.png",
        title: 'Делаешь(те) 100+ лидов дейли',
        description: '100 лидов для разогрева, чем больше тем лучше, 500-1000 +.'
    },

]

const Cooperation = () => {
    // let title = 'About Me',
    //     description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered <a href="#">alteration</a> in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum,';
    // const PostList = BlogContent.slice(0, 3);
    return (
        <div className="active-dark">
            <Helmet pageTitle="Portfolio Landing" />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Slider Area   */}
            <div id="home" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value, index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">

                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title" dangerouslySetInnerHTML={{ __html: value.title }}></h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */}


            {/* Start Service Area  */}
            <div className="service-area creative-service-wrapper ptb--120 bg_color--5" id="service">
                <div className="container">
                    <div className="row creative-service">
                        <div className="col-lg-12">
                            <AdvantageList item="6" column="col-lg-6 col-md-6 col-sm-6 col-12 text-left d-flex" />
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Area  */}


            {/* Start Service Area  */}
            {/* <div className="service-area creative-service-wrapper ptb--120 bg_color--4" id="service">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="feature-area">
                                <h3 className="title mt--20 fontWeight500 lineheight--1-8">Мы ищем арбитражников с опытом. И неважно одиночка ты или Вас целая команда. Если ты или Вы:</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row service-one-wrapper">
                        {AbilityListOne.map((val, i) => (
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-flex" key={i}>
                                <div className="service service__style--1">
                                    <div className="icon">
                                        <img src={`/assets/images/icons/${val.icon}`} alt="Service Icon" />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">{val.title}</h4>
                                        <p className="color-3">{val.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div> */}
            {/* End Service Area  */}

            {/* Start Portfolio Area */}
            <div className="portfolio-area pb--120 bg_color--4">
                <ContactFour />
            </div>
            {/* End Portfolio Area */}

            <FooterTwo />
        </div>
    )
}

export default Cooperation;